import React from 'react';
import { Container } from '@material-ui/core';
import { useStyles } from './styles';
import Questions from '../Questions';
import InstallSolarPanels from '../InstallSolarPanels';
import StartSavingWithSolar from '../StartSavingWithSolar';
import SolarIncentivesAndTaxCredits from '../SolarIncentivesAndTaxCredits';
import ElectricityRate from '../ElectricityRate';
import ReadyToSwitch from '../ReadyToSwitch';

const Content: React.FC = () => {
  const classes = useStyles();

  return (
    <Container component="section" maxWidth="lg" className={classes.root}>
      <div className={classes.copyContainer}>
        <ElectricityRate />
        <InstallSolarPanels />
        <StartSavingWithSolar />
        <SolarIncentivesAndTaxCredits />
        <ReadyToSwitch />
        <Questions />
      </div>
    </Container>
  );
};

export default Content;
