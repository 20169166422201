import React from 'react';
import { Container, Typography } from '@material-ui/core';
import { Link } from 'gatsby';

import { useStyles } from './styles';
import { Routes } from '../../../utils/Routes';

const ReadyToSwitch: React.FC = () => {
  const classes = useStyles();

  return (
    <>
      <Container component="section" maxWidth="lg" className={`${classes.root} ${classes.headerContainer}`}>
        <Typography variant="h2">Ready to Switch to Solar Energy In Florida?</Typography>
      </Container>
      <Container component="section" maxWidth="lg" className={classes.root}>
        <Typography variant="body1">
          Get in touch with a solar installation specialist at Solvana today for your&nbsp;
          <Link to={Routes.ContactUs}>free quote</Link>.
        </Typography>
      </Container>
    </>
  );
};

export default ReadyToSwitch;
