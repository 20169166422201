import React from 'react';
import { Container, Typography } from '@material-ui/core';
import { useStyles } from './styles';
import { Routes } from '../../../utils/Routes';
import { Link } from 'gatsby';

const ElectricityRate: React.FC = () => {
  const classes = useStyles();

  return (
    <Container component="section" maxWidth="lg" className={classes.root}>
      <Typography variant="body1">
        When the&nbsp;
        <a href="https://www.saveonenergy.com/learning-center/post/electricity-bills-by-state/">
          typical electricity rate
        </a>
        &nbsp;for regular power in Florida is considered, consumers who invest in residential solar panels may save more
        than $900 per year in energy bills.
      </Typography>
      <Typography variant="body1">
        The dropping cost of solar panels, which are currently accessible in Florida at an average cost of about $2.58
        per watt before subsidies, has increased the value proposition of solar. When you combine the lower cost of
        solar panels with large government subsidies, favorable utility programs, and Florida&#39;s Property Assessed
        Clean Energy (PACE) financing, getting solar in Florida is now a better value than ever.
      </Typography>
      <Typography variant="body1">
        Solvana is among the best solar panel installation companies in Florida. When you choose Solvana to convert your
        home to solar energy, you receive a trusted team of in-house solar specialists that can design and install an
        efficient solar panel system adapted to your individual needs.
      </Typography>
      <Typography variant="body1">
        <Link to={Routes.ContactUs}>Contact our solar specialist</Link>
        &nbsp;for a free solar quote based on your property&#39;s needs.
      </Typography>
    </Container>
  );
};

export default ElectricityRate;
