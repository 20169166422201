import React from 'react';
import { Container, Typography } from '@material-ui/core';
import { useStaticQuery, graphql, Link } from 'gatsby';
import Img from 'gatsby-image';

import { useStyles } from './styles';
import { Routes } from '../../../utils/Routes';

const InstallSolarPanels: React.FC = () => {
  const classes = useStyles();

  const data = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "florida-solar-panel-installation-2.png" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image2: file(relativePath: { eq: "florida-solar-panel-installation-3.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image3: file(relativePath: { eq: "florida-solar-panel-installation-4.png" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <>
      <Container component="section" maxWidth="lg" className={`${classes.root} ${classes.headerContainer}`}>
        <Typography variant="h2">How We Install Solar Panels in Florida</Typography>
      </Container>
      <Container component="section" maxWidth="lg" className={classes.root}>
        <div className={classes.leftContainer}>
          <Typography variant="body1">
            We are now assisting Florida residents and business owners in locations such as Tampa Bay, Miami, and Fort
            Lauderdale, among other cities to make the switch to solar energy in&nbsp;
            <Link to={Routes.HowItWorks}>three simple steps</Link>
            &nbsp;&mdash; at their own pace and convenience.
          </Typography>
          <Typography variant="body1">
            <Img Tag="span" fluid={data.image.childImageSharp.fluid} />
          </Typography>
          <Typography variant="body1">
            <b>Step 1: Sign up for your no-obligation solar energy proposal</b>
          </Typography>
          <Typography variant="body1">
            <b>Step 2: Determine and select the best solar energy solution for you</b>
          </Typography>
          <Typography variant="body1">
            <b>Step 3: Let us Install and set up the solar panels</b>
          </Typography>
          <Typography variant="body1">
            After you&#39;ve accomplished those three steps, sit back and relax because we&#39;ll take it from there.
            We&#39;re pleased to execute and manage the entire process for you and get your solar energy up and running
            with over 50 years of combined expertise in the solar sector and construction.
          </Typography>
          <Typography variant="body1">
            Among the most pragmatic reasons to choose Florida solar power is that the kilowatt-hours of energy produced
            by the panels are substantially cheaper throughout the life of the panels. The initial outlay may be
            intimidating, but installing Florida solar power means you&#39;ll be generating your own electricity for
            free following your payback time.
          </Typography>
          <Typography variant="body1">
            <Img Tag="span" fluid={data.image2.childImageSharp.fluid} />
          </Typography>
          <Typography variant="h3">Don&rsquo;t Let Storms Knock Out Your Power Again</Typography>
        </div>
        <div className={classes.rightContainer}>
          <Typography variant="body1">
            When a power outage caused by a Florida storm leaves you without power, it&#39;s tempting to become more
            energy independent. Many people choose Florida solar panels, particularly ones with a solar battery since it
            reduces their dependency on the power grid for electricity. Surges in grid demand will have no effect on
            your personal solar panel expenses, and power supply concerns will have less of an impact on your home
            system.
          </Typography>
          <Typography variant="body1">
            A smart house battery for solar energy storage may be used to store excess energy generated by your solar
            panel array for use when you need it the most. This means more savings, lesser carbon emissions, increased
            energy independence, and greater control over your renewable solar energy. It also means that when the sun
            goes down, clouds roll in, or your neighborhood&#39;s lights go out, you can rely on clean solar energy to
            satisfy your needs.
          </Typography>
          <Typography variant="body1">
            We are solar panel installers in Florida as well as battery specialists at Solvana. We create systems that
            will keep you running through storms, outages, and the night. To address our clients&#39; home battery
            demands in Florida, we rely on best-in-class technology as well as BESS. Based on your individual energy
            needs, storage unit availability, cost, and preferences, we&#39;ll recommend the best home battery choice
            for your home.
          </Typography>
          <Typography variant="body1">
            Explore our diverse selection of stylish, future-ready panels, adders, batteries, and inverters all of which
            may be customized &nbsp;to meet your specific requirements.
          </Typography>
          <Typography variant="body1">
            Also, our inventive pergolas provide a good mix of function and price. They&#39;re typically used with a
            full-roof solar system.
          </Typography>
          <Typography variant="body1">
            <Img Tag="span" fluid={data.image3.childImageSharp.fluid} />
            <Typography variant="caption">
              <a href="https://www.houzz.com/photos/solar-pergola-traditional-patio-philadelphia-phvw-vp~3267094">
                Source: Houzz
              </a>
            </Typography>
          </Typography>
          <Typography variant="body1">
            We typically seek to position these pergolas away from shaded trees in order to generate more solar energy,
            but with these outdoor leisure areas, consistent operation is sometimes more important than production.
          </Typography>
          <Typography variant="body1">
            We also conceal conduit such that it runs through the attic wherever feasible to keep your array looking
            sleek and professional. Other businesses don&rsquo;t do this.
          </Typography>
          <Typography variant="body1">
            Are you ready to go solar in Florida? Click&nbsp;
            <Link to={Routes.SmartFastEasy}>here</Link>
            &nbsp;to get started today.
          </Typography>
        </div>
      </Container>
    </>
  );
};

export default InstallSolarPanels;
