import React from 'react';
import { Container, Typography } from '@material-ui/core';
import { useStyles } from './styles';

const Questions: React.FC = () => {
  const classes = useStyles();

  const schemaFaq = {
    '@context': 'https://schema.org',
    '@type': 'FAQPage',
    mainEntity: [
      {
        '@type': 'Question',
        name: 'Is solar worth it in Florida?',
        acceptedAnswer: {
          '@type': 'Answer',
          text:
            'Of course! The Sunshine State is one of the best states for solar energy. The abundance of sun and the Federal 30% Investment Tax Credit make solar affordable. Adding Batteries is also a huge value add which can power your home during a blackout!',
        },
      },
      {
        '@type': 'Question',
        name: 'How much does it cost to put solar panels on your house in Texas?',
        acceptedAnswer: {
          '@type': 'Answer',
          text:
            'Solar costs less than renting your energy from your utility company forever. With tax credits and an abundance of sun, solar is cash flow positive, meaning it costs you less per month than your current electric bill!',
        },
      },
      {
        '@type': 'Question',
        name: 'How much does it cost to go solar in Florida?',
        acceptedAnswer: {
          '@type': 'Answer',
          text:
            'Solar costs are less than your current plan of renting your energy from your utility company forever... With Tax credits and an abundance of Sun, Solar is Cash Flow positive, meaning it costs you less per month than your current electric bill!',
        },
      },
      {
        '@type': 'Question',
        name: 'Does Florida have any solar incentives?',
        acceptedAnswer: {
          '@type': 'Answer',
          text:
            'Net metering and no sales tax are huge value adds for solar panel purchases. Plus, the sunshine state has a lot of sunshine! These incentives plus increases in solar panel efficiency provide huge value for our customers.',
        },
      },
      {
        '@type': 'Question',
        name: 'Does Florida have a free solar program?',
        acceptedAnswer: {
          '@type': 'Answer',
          text:
            "No, there is no such thing as free solar panels, and we do not recommend signing up with a third-party owner for a PPA or Lease. Third-party owned systems provide little to no long-term value to homeowners and often end up costing thousands of dollars. However, the federal government offers a 30% tax credit on the installed price of the system (including batteries charged by solar). Buying Solar panels is like buying your energy in bulk for the next 25 years, and everyone knows when you buy in bulk you save!. That's why everyone loves shopping at Costco and Sam's Club.",
        },
      },
      {
        '@type': 'Question',
        name: 'What is the best solar power company in Florida?',
        acceptedAnswer: {
          '@type': 'Answer',
          text:
            "Solvana, Hands down. We provide custom quotes and provide the best value to our customers. We are here for the long haul, not just looking for a quick sale. Our systems provide the best value. For instance, We use Iron Ridge XR100 solar mounting rails which are stronger and more durable long-term, versus other companies using the smaller, less strong Iron Ridge XR10. In short, we pick the best equipment for you, like it's going in our own homes.",
        },
      },
      {
        '@type': 'Question',
        name: 'What is the 30% solar tax credit for in Florida?',
        acceptedAnswer: {
          '@type': 'Answer',
          text:
            'The Federal Tax credit is applied to your federal taxes. This solar tax credit is available for Florida home solar panel systems installed by December 31, 2022. Ask your tax professional how you can best use the 30% Tax Credit.',
        },
      },
    ],
  };

  return (
    <>
      <Container component="section" maxWidth="lg" className={`${classes.root} ${classes.headerContainer}`}>
        <Typography variant="h2">Questions About Solar Energy in Florida?</Typography>
      </Container>
      <Container component="section" maxWidth="lg" className={classes.root}>
        <div className={classes.leftContainer}>
          <Typography variant="h3">Is solar worth it in Florida?</Typography>
          <Typography variant="body1">
            Of course! The Sunshine State is one of the best states for solar energy. The abundance of sun and the
            Federal 30% Investment Tax Credit make solar affordable. Adding Batteries is also a huge value add which can
            power your home during a blackout!
          </Typography>
          <Typography variant="h3">How much does it cost to go solar in Florida?</Typography>
          <Typography variant="body1">
            Solar costs are less than your current plan of renting your energy from your utility company forever... With
            Tax credits and an abundance of Sun, Solar is Cash Flow positive, meaning it costs you less per month than
            your current electric bill!
          </Typography>
          <Typography variant="h3">Does Florida have any solar incentives?</Typography>
          <Typography variant="body1">
            Net metering and no sales tax are huge value adds for solar panel purchases. Plus, the sunshine state has a
            lot of sunshine! These incentives plus increases in solar panel efficiency provide huge value for our
            customers.
          </Typography>
          <Typography variant="h3">Does Florida have a free solar program?</Typography>
          <Typography variant="body1">
            No, there is no such thing as free solar panels, and we do not recommend signing up with a third-party owner
            for a PPA or Lease. Third-party owned systems provide little to no long-term value to homeowners and often
            end up costing thousands of dollars. However, the federal government offers a 30% tax credit on the
            installed price of the system (including batteries charged by solar). Buying Solar panels is like buying
            your energy in bulk for the next 25 years, and everyone knows when you buy in bulk you save!. That&#39;s why
            everyone loves shopping at Costco and Sam&#39;s Club.
          </Typography>
        </div>
        <div className={classes.rightContainer}>
          <Typography variant="h3">What is the best solar power company in Florida?</Typography>
          <Typography variant="body1">
            Solvana, Hands down. We provide custom quotes and provide the best value to our customers. We are here for
            the long haul, not just looking for a quick sale. Our systems provide the best value. For instance, We use
            Iron Ridge XR100 solar mounting rails which are stronger and more durable long-term, versus other companies
            using the smaller, less strong Iron Ridge XR10. In short, we pick the best equipment for you, like it&#39;s
            going in our own homes.
          </Typography>
          <Typography variant="h3">What is the 30% solar tax credit for in Florida?</Typography>
          <Typography variant="body1">
            The Federal Tax credit is applied to your federal taxes. This solar tax credit is available for Texas home
            solar panel systems installed by December 31, 2022. Ask your tax professional how you can best use the 30%
            Tax Credit.
          </Typography>
          <Typography variant="body1">
            You&rsquo;ll need to meet the following criteria to apply for this credit on your Federal taxes:
          </Typography>
          <Typography component="div" variant="body1">
            <ul>
              <li>
                You have installed a home solar energy system at any point from 2006 through the end of 2022, even if
                it&#39;s not your primary residence
              </li>
              <li>
                The installation took place on a solar panel system that you own outright, either through cash purchase
                or a solar loan
              </li>
            </ul>
          </Typography>
        </div>
        <script type="application/ld+json">{JSON.stringify(schemaFaq)}</script>
      </Container>
    </>
  );
};

export default Questions;
