import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { Container, Typography } from '@material-ui/core';
import { useStyles } from './styles';

const Hero: React.FC = () => {
  const classes = useStyles();

  const data = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "florida-solar-panel-installation-1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <Container maxWidth="lg" className={classes.root}>
      <div className={classes.leftContainer}>
        <div className={classes.innerContainer}>
          <Typography color="primary" variant="h1">
            Solar Panel Installation in Florida
          </Typography>
          <Typography variant="body1">
            Florida is now rated fifth in the United States in terms of solar energy generation.
          </Typography>
          <Typography variant="body1">
            Despite the popularity of solar power in Florida, there is still a lot of untapped potential in the state.
            Because of the abundance of sunlight, most Florida homes are excellent candidates for residential solar
            panels. This might allow Floridians to generate most, if not all, of their own power.
          </Typography>
          <Typography variant="body1">
            Domestic solar panels are becoming more popular among Florida homeowners who are learning about the
            advantages of having a solar panel system. The Sunshine State offers an abundance of sunlight, lower rooftop
            solar prices, and energy cost benefits for solar households.
          </Typography>
        </div>
      </div>
      <div className={classes.rightContainer}>
        <Img fluid={data.image.childImageSharp.fluid} />
      </div>
    </Container>
  );
};

export default Hero;
