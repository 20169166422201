import React from 'react';
import Content from './Content';
import Hero from './Hero';

const SolarPanelInstallationFlorida: React.FC = () => {
  return (
    <>
      <Hero />
      <Content />
    </>
  );
};

export default SolarPanelInstallationFlorida;
