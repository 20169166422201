import { createStyles, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'start',
      padding: theme.spacing(0, 3, 12),
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        padding: theme.spacing(0, 2),
      },
      '& span': {
        display: 'block',
      },
    },
    rightContainer: {
      textAlign: 'left',
      width: '50%',
      padding: 0,
      zIndex: 2,
      '& p': {
        paddingRight: theme.spacing(8),
        [theme.breakpoints.down('md')]: {
          padding: 0,
        },
      },
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        alignItems: 'center',
        padding: theme.spacing(3, 0),
      },
    },
    buttonContainer: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '7vh',
    },
    getStartedButton: {
      marginTop: theme.spacing(3),
      maxWidth: '280px',
      color: '#fff',
      [theme.breakpoints.down('md')]: {
        maxWidth: '220px',
      },
    },
    leftContainer: {
      textAlign: 'left',
      width: '50%',
      padding: theme.spacing(0, 10, 0, 0),
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        alignItems: 'center',
        padding: theme.spacing(3, 0),
      },
    },
    headerContainer: {
      textAlign: 'center',
      width: '100%',
      padding: 0,
    },
  }),
);
