import React from 'react';
import { Container, Typography } from '@material-ui/core';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import { useStyles } from './styles';

const StartSavingWithSolar: React.FC = () => {
  const classes = useStyles();

  const data = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "florida-solar-panel-installation-5.png" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <>
      <Container component="section" maxWidth="lg" className={`${classes.root} ${classes.headerContainer}`}>
        <Typography variant="h2">Start Saving with Solar</Typography>
      </Container>
      <Container component="section" maxWidth="lg" className={classes.root}>
        <div className={classes.leftContainer}>
          <Typography variant="body1">
            As of March 2022, the &nbsp;
            <a href="https://www.solarreviews.com/solar-panel-cost/florida">average cost of solar panels</a>
            &nbsp;in Florida is $2.58 per watt. This puts the cost of a typical 6 kW solar system at $15,660 before any
            incentives and $11,442 after the 26 percent solar tax credit.
          </Typography>
          <Typography variant="body1">
            While it is a large sum of money, if all incentives are considered, you will end up paying much less. Solar
            panels in Florida are also less expensive than the national average for home solar power systems, which is
            now $3.00 per watt.
          </Typography>
          <Typography variant="body1">
            Installing a suitably sized renewable energy system in Florida might save you up to $80,000 in avoided
            energy expenditures. This is approximately average for the United States. The following example demonstrates
            the potential solar savings for a typical Hardee County, Florida home.
          </Typography>
          <Typography variant="caption">
            <Img Tag="span" fluid={data.image.childImageSharp.fluid} />
            Solar system costs and savings for an average home in Hardee County, with a monthly utility bill of $169.
          </Typography>
          <Typography variant="body1">
            Your precise savings will be determined by a variety of factors, including utility provider rates, solar
            panel installation costs in Florida, and the environment in which you live. Above all, your overall savings
            will be determined by your energy use; the more power your home uses, the more you will save with solar
            panels.
          </Typography>
        </div>
        <div className={classes.rightContainer}>
          <Typography variant="body1">
            Solar energy systems in Florida typically pay for themselves in approximately 10 years, but the payback
            period can be as short as 5 years. Some major aspects that influence your repayment time are as follows:
          </Typography>
          <Typography component="div" variant="body1">
            <ul>
              <li>Your solar system&#39;s size</li>
              <li>Your power tariff</li>
              <li>Your total electricity consumption</li>
              <li>Whether you utilize a lot of power during the day or not</li>
            </ul>
          </Typography>
          <Typography variant="body1">
            To optimize your savings, you should reduce your use as much as possible; this will result in your solar
            system sending more energy to the grid, which you get paid for!
          </Typography>
          <Typography variant="body1">
            The time of day when you use the most electricity also has a significant role in the length of time it takes
            to pay for your solar system. If you are at home during the day when your solar system produces the maximum
            energy, your solar system will export less electricity to the grid. The less energy you send to the grid,
            the less money your utility must give you in net metering credits. Although this is still less expensive
            than not having solar, it will raise your utility costs.
          </Typography>
          <Typography variant="body1">
            Many people think about a solar lease, in which a solar business puts solar panels on your home, you use the
            power, and the corporation owns the panels. You would be charged a monthly fee to cover the cost of the
            panels&#39; leasing. While the cost you pay may be cheaper than the traditional power tariff in your
            location, the leased panels will never be &quot;truly yours.&quot;
          </Typography>
        </div>
      </Container>
    </>
  );
};

export default StartSavingWithSolar;
