import React from 'react';
import { Container, Typography } from '@material-ui/core';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import { useStyles } from './styles';

const SolarIncentivesAndTaxCredits: React.FC = () => {
  const classes = useStyles();

  const data = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "florida-solar-panel-installation-6.png" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image2: file(relativePath: { eq: "florida-solar-panel-installation-7.png" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <>
      <Container component="section" maxWidth="lg" className={`${classes.root} ${classes.headerContainer}`}>
        <Typography variant="h2">Florida Solar Incentives and Tax Credits</Typography>
      </Container>
      <Container component="section" maxWidth="lg" className={classes.root}>
        <div className={classes.leftContainer}>
          <Typography variant="body1">
            Governments in Florida use incentives and rebates to encourage consumers to install solar panels. Tax
            credits, rebates, and other Florida solar incentives can help reduce the initial cost of solar panels.
          </Typography>
          <Typography variant="body1">
            <Img Tag="span" fluid={data.image.childImageSharp.fluid} />
            <Typography variant="caption">
              <a href="https://pixabay.com/photos/money-home-coin-investment-2724245/">Source: Pixabay</a>
            </Typography>
          </Typography>
          <Typography variant="body1">
            Florida provides a number of&nbsp;
            <a href="https://www.energysage.com/local-data/solar-rebates-incentives/fl/">
              rebates and solar incentives
            </a>
            &nbsp;that might help you save money on your solar panel purchase. One regulation mandated that no sales tax
            be levied on solar panels, and other localities have unique incentive schemes for renewable energy
            generation in their region. Low-interest loans are also available in some locations to help spread out the
            costs.
          </Typography>
          <Typography variant="body1">
            Another Florida solar incentive is net metering, which allows you to sell back surplus solar energy to the
            power grid and get bill credits when your panels create more energy than you need.
          </Typography>
        </div>
        <div className={classes.rightContainer}>
          <Typography variant="body1">
            <a href="https://www.myfloridahouse.gov/Sections/Bills/billsdetail.aspx?BillId%3D75044">House Bill 741</a>
            &nbsp;and&nbsp;
            <a href="https://www.flsenate.gov/Session/Bill/2022/1024">Senate Bill 1024</a>
            &nbsp;both passed in the Florida Legislature, which would make huge changes in&nbsp;
            <a href="https://www.youtube.com/watch?v%3DjTTUNTtLBBA">net metering programs</a>, the credit programs that
            determine how rooftop solar energy owners are currently subsidized by utility companies.
          </Typography>
          <Typography variant="body1">
            <Img Tag="span" fluid={data.image2.childImageSharp.fluid} />
            <Typography variant="caption">
              <a href="https://pixabay.com/photos/gavel-auction-hammer-justice-legal-3577258/">Source: Pixabay</a>
            </Typography>
          </Typography>
          <Typography variant="body1">
            The federal government of the United States has also extended a significant 26 percent solar tax credit for
            individuals installing solar panels in Florida in 2021 and 2022. This tax credit may take some time to come,
            but when you do, you will be able to cover more than a fourth of the cost of solar panels in Florida.
          </Typography>
          <Typography variant="body1">
            Another Florida solar incentive is&nbsp;
            <a href="https://www.solarunitedneighbors.org/florida/learn-the-issues-in-florida/net-metering-in-florida/">
              net metering
            </a>
            , which allows you to sell back surplus solar energy to the power grid and get bill credits when your panels
            create more energy than you need.
          </Typography>
        </div>
      </Container>
    </>
  );
};

export default SolarIncentivesAndTaxCredits;
