import React from 'react';
import Layout from '../containers/Layout';
import SolarPanelInstallationFlorida from '../containers/SolarPanelInstallationFlorida';

const SolarPanelInstallationFloridaPage: React.FC = () => (
  <Layout title="Solar Panel Installation Florida | Go Solar Today | Solvana">
    <SolarPanelInstallationFlorida />
  </Layout>
);

export default SolarPanelInstallationFloridaPage;
