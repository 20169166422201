import { createStyles, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'start',
      flexDirection: 'column',
      padding: theme.spacing(0, 3, 12),
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(0, 2),
      },
      '& span': {
        display: 'block',
      },
    },
  }),
);
